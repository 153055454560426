.stakeRow {
    background-color: transparent;
}

.stakeRow:hover {

    background-color: rgb(192, 192, 192);
    cursor: pointer;
}

.stakeButton {

    background-color: #346;
    color:white;
    min-width: 120px;
}